// src/pages/HowItWorks.js
import React from 'react';

const HowItWorks = () => (
  <div className="container mt-5">
    <h2>How It Works</h2>
    <p>Description about the project....</p>
  </div>
);

export default HowItWorks;
